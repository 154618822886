import { useState, useEffect, useRef } from "react";
import { BotonProcesar } from "../../components/buttons/BotonProcesar";
import { BotonNuevo } from "../../components/buttons/BotonNuevo";
import { Modal } from "../../components/modal/Modal";
import { useModal } from "../../../hooks/useModal";
import { classNameInput, notify } from "../../../utils/utils";
import { ContenedorParametros } from "../../components/utils/ContenedorParametros";

import "../../../assets/css/views/maps.css";

import { GoogleMap } from "../../../utils/googlemaps";
import BotonSwitch from "../../components/buttons/BotonSwitch";

const google = window.google;
const googleMap = new GoogleMap();
const googleMapModal = new GoogleMap();

let drawingManager1;

export const RutasAcceso = () => {
    const mapDiv = useRef();
    const mapDivModal = useRef();
    const refTablaMarcadores = useRef()

    const [isOpenModalDetalle, openModalDetalle, closeModalDetalle] = useModal();
    
    const [isViewMap,setIsViewMap] = useState(false)
    const [isDranwingRute,setIsDrawingRute] = useState(false)
    const [marcadoresRuta,setMarcadoresRuta] = useState([])

    const rutasAcceso = [
        {
            cp: "C.P.PATERINE1",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERINE1",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERINE1",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERINE2",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERINE2",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERINE3",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERIN3",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERINE4",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERINE56",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERINE6",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERINE",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERINE",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERINE",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
        {
            cp: "C.P.PATERINE",
            puntoInicia: "Lima",
            puntoDestino: "C.P.PATERINE",
            distancia: "15.8km",
            epoca: "Verano",
            tipoTrasporte: "Particular",
            metodoViaje: "Auto",
            tiempo: "5horas",
            estado: "Activo",
            comentario: "Esta es una via que se usa para etc.....",
        },
    ];

    useEffect(() => {
        const funcionReady = async () => {
            googleMap.inicializarMapa(mapDiv.current, { zoom: 12 });
        };

        funcionReady();
    }, []);

    useEffect(() => {
        if (isOpenModalDetalle) {
            googleMapModal.inicializarMapa(mapDivModal.current, {zoom: 12,ocultarMarcadores: false,cursor:"pointer"});

            googleMapModal.map.addListener('click',(event) => {     // EVENTO PARA GUARDAR LOS MARCADORES OBTENER LOS PUNTOS
                const latLng = event.latLng
                const marcador = googleMapModal.crearMarcador({
                    latLng: event.latLng,
                    icon: {
                        url: '/marcador-alfiler.png',
                        scaledSize: new google.maps.Size(40, 40), // Tamaño del marcador
                    }
                })
                setMarcadoresRuta(valorAnterior => {
                    const copia = [...valorAnterior]
                    const nuevoJson = {
                        marcadorRef : marcador,
                        latitud : latLng.lat(),
                        longitud: latLng.lng()
                    }
                    return [
                        ...copia,
                        nuevoJson
                    ]
                })
            })
        }else{
            setIsDrawingRute(false)
            setMarcadoresRuta([])
        }
    }, [isOpenModalDetalle]);

    useEffect(() => {
        refTablaMarcadores.current?.scrollIntoView({ behavior: "smooth", block: "end" });
        googleMapModal.quitarTodosLasPolilineas()
        if (marcadoresRuta.length != 0){
            googleMapModal.crearPolilinea({
                listLatLng: marcadoresRuta.map(data => ({lat:data.latitud,lng:data.longitud})),
                strokeWeight: 3,
                color: 'red'
            })
        }
    },[marcadoresRuta])

    const crearDrawingManager = () => {
        drawingManager1 = new window.google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_LEFT,
                drawingModes: [
                    google.maps.drawing.OverlayType.MARKER, // Habilita dibujar marcadores
                    google.maps.drawing.OverlayType.POLYGON, // Habilita dibujar polígonos
                ],
            },
            polygonOptions: {
                fillColor: "#42a7f1",
                strokeColor: "#3454ff",
            },
        });
    };

    return (
        <>
            <div className="w-full h-full flex flex-col">
                <ContenedorParametros titulo={"Rutas de Acceso"}>
                        <div className="flex gap-2 items-center">
                            <i className="fas fa-home"></i>
                            <select className={`${classNameInput} h-[30px]`}>
                                <option value="0">-- Seleccione --</option>
                            </select>
                        </div>
                    
                        <BotonNuevo
                            onClick={() => {
                                openModalDetalle();
                            }}
                        />
                        
                        <div className="flex items-center gap-2">
                            <span>Ver mapa</span>
                            <BotonSwitch isOpen={isViewMap} dioClick={valor => setIsViewMap(valor)}></BotonSwitch>
                        </div>
                </ContenedorParametros>

                <div className="flex-grow flex flex-col gap-4 overflow-auto">
                    <div className={`w-full h-full ${isViewMap ? "" : "hidden"}`}>
                        <div className={"w-full h-full"} ref={mapDiv}></div>
                    </div>

                    <div
                        className={`w-full h-full overflow-auto containerScroll ${isViewMap ? "hidden" : ""}`}
                    >
                        <table
                            id="tabla-entidades"
                            className="table lg:text-xs"
                        >
                            <thead>
                                <tr>
                                    <th className="w-[30px] text-left">NOMBRE</th>
                                    <th>PUNTO INCIIAL</th>
                                    <th>PUNTO DESTINO</th>
                                    <th>DISTANCIA</th>
                                    <th>EPOCA</th>
                                    <th>TIPO DE TRANSPORTE</th>
                                    <th>METODOS DE VIAJES</th>
                                    <th>TIEMPO</th>
                                    <th>COMENTARIO</th>
                                    <th>ESTADO</th>

                                    <th className="w-2"></th>
                                    <th className="w-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {rutasAcceso.length > 0 ? (
                                    rutasAcceso.map((ent, i) => {
                                        return (
                                            <tr
                                                key={i++}
                                                className={`${
                                                    ent.tieneDatosCompletos &&
                                                    "datosIncompletos"
                                                } bg-green-9`}
                                            >
                                                <td className="text-left">
                                                    {ent.cp}
                                                </td>
                                                <td className="text-center">
                                                    {ent.puntoInicia}
                                                </td>
                                                <td className="text-center">
                                                    {ent.puntoDestino}
                                                </td>
                                                <td className="text-center">
                                                    {ent.distancia}
                                                </td>
                                                <td className="text-center">
                                                    {ent.epoca}
                                                </td>
                                                <td className="text-center">
                                                    {ent.tipoTrasporte}
                                                </td>
                                                <td className="text-center">
                                                    {ent.metodoViaje}
                                                </td>
                                                <td className="text-center">
                                                    {ent.tiempo}
                                                </td>
                                                <td className="text-center">
                                                    {ent.comentario}
                                                </td>
                                                <td className="text-center">
                                                    {ent.estado}
                                                </td>

                                                <td className="text-center">
                                                    <button
                                                        onClick={() => {
                                                            openModalDetalle();
                                                        }}
                                                        className="bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded"
                                                    >
                                                        <i className="fas fa-edit text-white"></i>
                                                    </button>
                                                </td>
                                                <td className="text-center">
                                                    <button className="bg-red-500 hover:bg-red-600 text-[14px] px-[5px] py-1 rounded">
                                                        <i className="fa fa-trash text-white"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td
                                            colSpan={"9"}
                                            className="text-center"
                                        >
                                            No hay información para mostrar
                                            por el momento
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isOpenModalDetalle}
                closeModal={() => {
                    closeModalDetalle();
                }}
                action={() => {
                    notify(
                        "Es obligatorio la ubicacion y la jurisdiccion de la entidad",
                        "error"
                    );
                }}
                title={`${"Ruta de Acceso"}`}
            >
                <div className="w-[80vw] h-[600px] flex lg:flex-col gap-5">
                    <div className="w-1/3 flex flex-col gap-4">
                        <div className="grid grid-cols-[auto_1fr] gap-4">
                            <label>Punto Inicial</label>
                            <select className={classNameInput}>
                                <option value={0}>
                                    -- Seleccione --
                                </option>
                                <option value={1}>
                                    Departamento
                                </option>
                                <option value={2}>
                                    Provincia{" "}
                                </option>
                                <option value={3}>Distrito</option>
                                <option value={4}>
                                    Centro Poblado
                                </option>
                            </select>

                            <label>Punto Destino</label>
                            <select className={classNameInput}>
                                <option value={0}>C.P. Pozito</option>
                            </select>

                            <label>Tipo de transporte</label>
                            <select className={classNameInput}>
                                <option value={0}>
                                    {" "}
                                    Particular{" "}
                                </option>
                                <option value={1}> Público </option>
                                <option value={4}> otro </option>
                            </select>

                            <label>Metodos de viaje</label>
                            <select className={classNameInput}>
                                <option value={0}> Auto </option>
                                <option value={1}> Tren </option>
                                <option value={2}> Avión </option>
                                <option value={3}> Bote </option>
                                <option value={4}> otro </option>
                            </select>

                            <label>Epoca</label>
                            <select className={classNameInput}>
                                <option value={0}>
                                    {" "}
                                    Invierno{" "}
                                </option>
                                <option value={1}> Verano </option>
                                <option value={2}> Enero </option>
                                <option value={3}> Lluvias </option>
                                <option value={4}> otro </option>
                            </select>

                            <label>Comentario</label>
                            <textarea
                                className={`${classNameInput} h-[100px]`}
                            ></textarea>

                            <label>Distancia: </label>
                            <input
                                className={`${classNameInput}`}
                                value={"15.8km"}
                                disabled
                            />

                            <label>Tiempo: </label>
                            <input
                                className={`${classNameInput}`}
                                value={"8 horas"}
                                disabled
                            />
                        </div>
                        <div className={`flex-grow flex flex-col gap-2 overflow-auto ${isDranwingRute ? '' : 'hidden'}`}>
                            <div>Puntos de ruta:</div>
                            <div className="flex-grow overflow-auto containerScroll">
                                <table ref={refTablaMarcadores} className="table">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Nº</th>
                                            <th className="text-left">COORD.</th>
                                            <th className="text-left"></th>
                                            <th className="text-left w-[50px]"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            marcadoresRuta.length != 0 ?
                                                marcadoresRuta.map((data,i) => {
                                                    return(
                                                        <tr key={i+1}>
                                                            <td>{i+1}</td>
                                                            <td>{data.latitud} - {data.longitud}</td>
                                                            <td><button className="w-[25px] h-[25px] rounded bg-blue-600" onClick={() => googleMapModal.enforcarMarcador(data.marcadorRef)}><i className="fas fa-search"></i></button></td>
                                                            <td>
                                                                <button 
                                                                    className="w-[25px] h-[25px] rounded bg-red-600" 
                                                                    onClick={() => {
                                                                        googleMapModal.quitarMarcador(data.marcadorRef);
                                                                        setMarcadoresRuta(arrAnterior => {
                                                                            const copia = [...arrAnterior]
                                                                            return copia.filter(elem => elem.marcadorRef != data.marcadorRef)
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            :   <tr><td colSpan={4} className="text-center">No hay marcadores para mostrar...</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="relative w-2/3 lg:w-full lg:h-[250px] flex flex-col justify-center">
                        <div className={`absolute w-full h-full bg-black bg-opacity-50 z-10 flex items-center justify-center ${isDranwingRute ? 'hidden' : ''}`}>
                            <button onClick={() => {setIsDrawingRute(true)}} className="rounded-sm hover:scale-105 p-4 bg-blue-600 opacity-100">Trazar ruta</button>
                        </div>
                        <div className={`${isDranwingRute ? '' : 'hidden'} absolute left-1/2 transition-all duration-500 ease-in-out bottom-0 whitespace-nowrap -translate-x-1/2  z-10 text-black font-bold text-xl drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]`}>Hacer click en los puntos de paso para generar la ruta</div>
                        <div
                            className="w-full h-full cursor-pointer"
                            ref={mapDivModal}
                        ></div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
